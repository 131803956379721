import React from 'react'
import Box from '../components/Box'
const NotFoundPage = () => (
  <Box>
    <h1>PAGE NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Box>
)

export default NotFoundPage
